<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <v-toolbar-title> Create {{ type }} Forecast </v-toolbar-title>
        <v-autocomplete
          class="ml-2"
          v-if="type == 'Customer'"
          :items="related"
          item-text="relatedOrganisation.name"
          rounded
          :label="type + '*'"
          item-value="relatedOrganisation.id"
          style="max-width: 400px; min-width: 300px"
          hide-details
          outlined
          v-model="item.customerId"
          clearable
          dense
        >
          <template v-slot:selection="data">
            <v-row align="center">
              <v-avatar
                size="32"
                :color="
                  data.item && data.item.relatedOrganisation.logo
                    ? 'white'
                    : 'secondary'
                "
                class="mr-2"
                v-if="data.item"
              >
                <v-img
                  v-if="data.item.relatedOrganisation.logo"
                  contain
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
              <span v-if="data.item.relatedOrganisation">
                {{ data.item.relatedOrganisation.name }}
              </span>
            </v-row>
          </template>
          <template v-slot:item="data">
            <v-list-item-action>
              <v-avatar
                size="32"
                :color="
                  data.item.relatedOrganisation.logo ? 'white' : 'secondary'
                "
              >
                <v-img
                  contain
                  v-if="data.item.relatedOrganisation.logo"
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                data.item.relatedOrganisation.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="data.item.relatedOrganisation.alias"
                >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-autocomplete
          class="ml-2"
          v-else
          :items="related"
          item-text="relatedOrganisation.name"
          rounded
          :label="type + '*'"
          item-value="relatedOrganisation.id"
          style="max-width: 400px; min-width: 300px"
          hide-details
          outlined
          v-model="item.supplierId"
          clearable
          dense
        >
          <template v-slot:selection="data">
            <v-row align="center">
              <v-avatar
                size="32"
                :color="
                  data.item && data.item.relatedOrganisation.logo
                    ? 'white'
                    : 'secondary'
                "
                class="mr-2"
                v-if="data.item"
              >
                <v-img
                  v-if="data.item.relatedOrganisation.logo"
                  contain
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
              <span v-if="data.item.relatedOrganisation">
                {{ data.item.relatedOrganisation.name }}
              </span>
            </v-row>
          </template>
          <template v-slot:item="data">
            <v-list-item-action>
              <v-avatar
                size="32"
                :color="
                  data.item.relatedOrganisation.logo ? 'white' : 'secondary'
                "
              >
                <v-img
                  contain
                  v-if="data.item.relatedOrganisation.logo"
                  :src="data.item.relatedOrganisation.logo"
                ></v-img>
                <h3 v-else>
                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                </h3>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                data.item.relatedOrganisation.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="data.item.relatedOrganisation.alias"
                >{{ data.item.relatedOrganisation.alias }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="save()"
          :loading="saving"
          :disabled="
            (type == 'Customer' && !item.customerId) ||
            (type == 'Supplier' && !item.supplierId) ||
            !item.seasonId ||
            !item.productId ||
            !item.destinationCountryId ||
            !item.plannedWeek ||
            item.orderCargoItems.length == 0
          "
          color="secondary"
        >
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn text @click="close()">X</v-btn>
      </v-toolbar>
      <v-card-text :key="refreshKey">
        <v-row>
          <v-col cols="12" sm="6">
            <v-list dense class="my-0 py-0">
              <v-row align="center">
                <v-col cols="12" sm="4" md="4" class="mt-3">
                  <v-list dense class="my-0 py-0 mt-2">
                    <v-select
                      label="Season*"
                      v-model="item.seasonId"
                      :items="seasons"
                      @change="setSeason"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                    ></v-select>
                    <v-autocomplete
                    :key="refreshKey"
                      class="my-2 mt-1"
                      label="Product*"
                      v-model="item.productId"
                      :items="filterProducts"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.type }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            v-if="data.item.parentProduct"
                            style="font-size: 12px"
                          >
                            Parent: {{ data.item.parentProduct.name }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template></v-autocomplete
                    >
                  </v-list>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="my-0 py-0 mt-1">
                  <v-list dense class="my-0 py-0 mt-1">
                    <v-row align="center">
                      <v-col cols="12" sm="6">
                        <v-select
                          outlined
                          dense
                          hide-details
                          v-model="item.weekType"
                          label="Week Type"
                          :items="['Pack Week', 'ETD Week', 'ETA Week']"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          :key="refreshKey"
                          class="my-1"
                          label="Week*"
                          hide-details
                          v-model="item.plannedWeekName"
                          :items="setWeeks"
                          item-text="name"
                          item-value="name"
                          @change="setPlannedYear"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          label="Target Country*"
                          :loading="loadingCountries"
                          v-model="item.destinationCountryId"
                          :items="countries.data"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                        >
                          <template v-slot:selection="data">
                            <v-avatar size="32" class="mr-2" v-if="data.item">
                              <v-img
                                v-if="data.item.iso2"
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                              >
                              </v-img>
                            </v-avatar>
                            <span v-if="data.item">
                              {{ data.item.name }}
                            </span>
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-avatar>
                              <v-img
                                contain
                                v-if="data.item.iso2"
                                :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                              >
                              </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          label="Base Unit"
                          v-model="item.baseUnit"
                          :items="productQuantityTypes"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0">
                  <v-list-item>
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Containerised</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="item.containerised"
                        hide-details
                        color="blue"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Palletised</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="item.palletised"
                        hide-details
                        color="blue"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0">
                  <v-list-item v-if="item.containerised && item.palletised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >Pallets per Container</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-input-number
                        :min="0"
                        :value="item.palletPerContainer"
                        size="mini"
                        v-model="item.palletPerContainer"
                      ></el-input-number>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="!item.containerised && item.palletised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ formatBaseUnit(item.baseUnit) }}(s) per
                        Pallet</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-input-number
                        :min="0"
                        :value="item.unitPerPallet"
                        size="mini"
                        v-model="item.unitPerPallet"
                      ></el-input-number>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="item.containerised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ formatBaseUnit(item.baseUnit) }}(s) per
                        Container</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-input-number
                        :min="0"
                        :value="item.unitPerContainer"
                        size="mini"
                        v-model="item.unitPerContainer"
                      ></el-input-number>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
                <!-- <v-col cols="12" sm="4" md="2"> -->
                <!-- <v-list-item>
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>currency_exchange</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Currency</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-autocomplete
                        style="width: 150px"
                        :items="currencies"
                        hide-details
                        dense
                        outlined
                        rounded
                        v-model="item.currencyCode"
                      ></v-autocomplete>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>attach_money</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Pricer per carton</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-text-field
                        style="width: 150px"
                        :prefix="getSymbol(item.currencyCode)"
                        dense
                        outlined
                        rounded
                        v-model="item.currencyValue"
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-list-item-action>
                  </v-list-item> -->
                <!-- </v-col> -->
              </v-row>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list dense class="my-0 py-0 mt-2">
              <v-list-item
                v-if="!item.consigneeProfile"
                @click="shipmentProfileDialog = true"
                :disabled="!item.customerId"
              >
                <v-list-item-action>
                  <v-icon color="grey">handshake</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Add Shipment Profile </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-card
                v-else
                style="background: var(--v-component-base) !important"
                @click="shipmentProfileDialog = true"
                :disabled="!item.customerId"
              >
                <v-card-title style="font-size: 14px">
                  <v-icon color="secondary" class="mr-2">handshake</v-icon
                  >Shipment Profile: {{ item.consigneeProfile.systemReference }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-list dense subheader>
                        <v-subheader style="font-size: 14px">
                          <v-icon class="mr-2" color="grey" small>group</v-icon>
                          Parties</v-subheader
                        >
                        <v-divider></v-divider>
                        <v-list-item v-if="item.consigneeProfile.shipper">
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">directions_boat</v-icon>
                              </template>
                              <span style="font-size: 12px">Shipper</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.consigneeProfile.shipper.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              v-if="item.consigneeProfile.onBehalfShipper"
                            >
                              On Behalf of
                              {{ item.consigneeProfile.onBehalfShipper.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.consigneeProfile.consignee">
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">call_received</v-icon>
                              </template>
                              <span style="font-size: 12px">Consignee</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.consigneeProfile.consignee.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              v-if="item.consigneeProfile.onBehalfConsignee"
                            >
                              On Behalf of
                              {{ item.consigneeProfile.onBehalfConsignee.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.consigneeProfile.forwarder">
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">fast_forward</v-icon>
                              </template>
                              <span style="font-size: 12px">Forwarder</span>
                            </v-tooltip>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.consigneeProfile.forwarder.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              v-if="item.consigneeProfile.onBehalfForwarder"
                            >
                              On Behalf of
                              {{ item.consigneeProfile.onBehalfForwarder.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-list dense subheader>
                        <v-subheader style="font-size: 14px">
                          <v-icon class="mr-2" color="grey" small
                            >settings</v-icon
                          >
                          Settings</v-subheader
                        >
                        <v-divider></v-divider>
                        <v-list-item v-if="item.consigneeProfile.originCountry">
                          <v-list-item-action>
                            <v-avatar size="32">
                              <v-img
                                contain
                                v-if="
                                  item.consigneeProfile.originCountry &&
                                  item.consigneeProfile.originCountry.iso2
                                "
                                :src="`https://cdn.loglive.io/flags/4x3/${item.consigneeProfile.originCountry.iso2.toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.consigneeProfile.originCountry.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              Origin Country
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-if="item.consigneeProfile.destinationCountry"
                        >
                          <v-list-item-action>
                            <v-avatar size="32">
                              <v-img
                                contain
                                v-if="
                                  item.consigneeProfile.destinationCountry &&
                                  item.consigneeProfile.destinationCountry.iso2
                                "
                                :src="`https://cdn.loglive.io/flags/4x3/${item.consigneeProfile.destinationCountry.iso2.toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.consigneeProfile.destinationCountry.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              Destination Country
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-if="item.consigneeProfile.finalDestinationCity"
                        >
                          <v-list-item-action>
                            <v-avatar size="32">
                              <v-img
                                contain
                                v-if="
                                  item.consigneeProfile.finalDestinationValue
                                "
                                :src="`https://cdn.loglive.io/flags/4x3/${item.consigneeProfile.finalDestinationValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.consigneeProfile.finalDestinationCity
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              Final Destination
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-list>
          </v-col>
          <v-col cols="12">
            <v-container v-if="item.containerised">
              <v-card
                style="border-radius: 20px"
                outlined
                v-for="(container, index) in constructedContainers"
                :key="index"
              >
                <v-card-title style="font-size: 14px"
                  >Group: {{ container.index }}
                  <!-- <v-spacer></v-spacer> -->
                  <v-chip small outlined dense v-if="item.containerised && groupContainers(container)" :color="validGroup(container)?'green':'red'" class="ml-2">
                    <v-icon v-if="validGroup(container)" small left>check</v-icon>
                    <v-icon small left v-else>warning</v-icon>
                    {{ groupContainers(container) }} Containers
                  </v-chip>
                  <v-chip small outlined dense v-if="item.palletised && groupPallets(container)" class="ml-2">
                    {{ groupPallets(container) }} Pallets
                  </v-chip>
                  <span v-if="!validGroup(container)" class="ml-2" style="color: red">
                  The quantity of the contents in this group does not match the container capacity
                  </span>
                  <v-spacer></v-spacer>
                  <!-- <v-btn icon color="red"><v-icon>close</v-icon></v-btn> -->
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    dense
                    hide-default-footer
                    disable-pagination
                    :headers="
                      item.palletised
                        ? cargoHeaders
                        : cargoHeaders.filter((x) => x.value != 'palletType')
                    "
                    :items="container.items"
                    style="background-color: transparent"
                  >
                    <!-- <template v-slot:[`header.quantity`]="{ header }">
                      {{ header.text }}
                      <span v-if="item.baseUnit" class="ml-1">
                        {{ ` (${formatBaseUnit(item.baseUnit)})` }}
                        <span v-if="!item.palletised"> in Tons</span></span
                      >
                    </template> -->
                    <template v-slot:[`item.actions`]="{ item, index }">
                      <v-container>
                        <v-row justify="center">
                          <v-btn icon small @click="duplicateItem(item)">
                            <v-icon small>content_copy</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="red"
                            small
                            @click="deleteItem(index, item)"
                          >
                            <v-icon small>cancel</v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                    </template>

                    <template v-slot:[`item.quantityType`]="{ item }">
                      <v-autocomplete
                        style="font-size: 12px; border: none"
                        outlined
                        dense
                        hide-details
                        :items="productQuantityTypes"
                        item-text="text"
                        item-value="value"
                        v-model="item.quantityType"
                        clearable
                        @change="saveLineItem(item)"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      <v-text-field
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        clearable
                        v-model="item.quantity"
                        @change="saveLineItem(item)"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.varietyProduct`]="{ item }">
                      <v-autocomplete
                        style="font-size: 12px; border: none"
                        outlined
                        dense
                        hide-details
                        :items="filterVarieties"
                        item-text="name"
                        item-value="id"
                        v-model="item.varietyProduct"
                        clearable
                        return-object
                        @change="setVariety($event, item)"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.packCode`]="{ item }">
                      <v-autocomplete
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        :items="filteredValues('packCode', item)"
                        item-text="value"
                        item-value="value"
                        clearable
                        @change="saveLineItem(item)"
                        v-model="item.packCode"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.countSize`]="{ item }">
                      <v-autocomplete
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        :items="filteredValues('countSize', item)"
                        item-text="value"
                        item-value="value"
                        clearable
                        @change="saveLineItem(item)"
                        v-model="item.countSize"
                      ></v-autocomplete>
                    </template>

                    <template v-slot:[`item.grade`]="{ item }">
                      <v-autocomplete
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        :items="filteredValues('grade')"
                        item-text="value"
                        item-value="value"
                        clearable
                        v-model="item.grade"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.mark`]="{ item }">
                      <v-autocomplete
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        :items="filteredValues('mark')"
                        item-text="value"
                        item-value="value"
                        @change="saveLineItem(item)"
                        clearable
                        v-model="item.mark"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.phc`]="{ item }">
                      <v-text-field
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        clearable
                        @change="saveLineItem(item)"
                        v-model="item.phc"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.puc`]="{ item }">
                  <v-text-field
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.puc"
                  ></v-text-field>
                </template>
                    <template v-slot:[`item.inventoryCode`]="{ item }">
                      <v-autocomplete
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        :items="filteredValues('inventoryCode')"
                        item-text="value"
                        item-value="value"
                        @change="saveLineItem(item)"
                        clearable
                        v-model="item.inventoryCode"
                      ></v-autocomplete>
                    </template>
                    <template v-slot:[`item.palletType`]="{ item }">
                      <v-autocomplete
                        width="100px"
                        class="ml-1"
                        dense
                        outlined
                        hide-details
                        :items="filteredValues('palletType')"
                        item-text="name"
                        item-value="value"
                        clearable
                        @change="saveLineItem(item)"
                        v-model="item.palletType"
                      ></v-autocomplete>
                    </template>

                    <template v-slot:footer>
                      <v-list-item
                        @click="addLineItem(container.containerIndex)"
                        style="background: var(--v-modal-base) !important"
                      >
                        <v-list-item-content class="text-center">
                          <v-icon color="primary">add_circle_outline</v-icon>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-list-item
                @click="addContainerLineItem()"
                style="background: var(--v-modal-base) !important"
              >
                <v-list-item-content class="text-center">
                  <v-container>
                    <v-row justify="center">
                      <v-chip
                        ><v-icon color="primary" left
                          >add_circle_outline</v-icon
                        >
                        Add Group</v-chip
                      >
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-container>
            <v-container v-else>
              <v-data-table
                dense
                hide-default-footer
                disable-pagination
                :headers="
                  item.palletised
                    ? cargoHeaders
                    : cargoHeaders.filter((x) => x.value != 'palletType')
                "
                :items="item.orderCargoItems"
                style="background-color: transparent"
              >
                <!-- <template v-slot:[`header.quantity`]="{ header }">
                  {{ header.text }}
                  <span v-if="item.baseUnit" class="ml-1">
                    {{ ` (${formatBaseUnit(item.baseUnit)})` }}
                    <span v-if="!item.palletised"> in Tons</span></span
                  >
                </template> -->
                <template v-slot:[`item.actions`]="{ item, index }">
                  <v-container>
                    <v-row justify="center">
                      <v-btn icon small @click="duplicateItem(item)">
                        <v-icon small>content_copy</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="red"
                        small
                        @click="deleteItem(index, item)"
                      >
                        <v-icon small>cancel</v-icon>
                      </v-btn>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:[`item.quantityType`]="{ item }">
                  <v-autocomplete
                    style="font-size: 12px; border: none"
                    outlined
                    dense
                    hide-details
                    :items="productQuantityTypes"
                    item-text="text"
                    item-value="value"
                    v-model="item.quantityType"
                    clearable
                    @change="saveLineItem(item)"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.quantity`]="{ item }">
                  <v-text-field
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    clearable
                    v-model="item.quantity"
                    @change="saveLineItem(item)"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.varietyProduct`]="{ item }">
                  <v-autocomplete
                    style="font-size: 12px; border: none"
                    outlined
                    dense
                    hide-details
                    :items="filterVarieties"
                    item-text="name"
                    item-value="id"
                    v-model="item.varietyProduct"
                    clearable
                    return-object
                    @change="setVariety($event, item)"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.packCode`]="{ item }">
                  <v-autocomplete
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('packCode', item)"
                    item-text="value"
                    item-value="value"
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.packCode"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.countSize`]="{ item }">
                  <v-autocomplete
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('countSize', item)"
                    item-text="value"
                    item-value="value"
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.countSize"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.phc`]="{ item }">
                  <v-text-field
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.phc"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.puc`]="{ item }">
                  <v-text-field
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.puc"
                  ></v-text-field>
                </template>
                

                <template v-slot:[`item.grade`]="{ item }">
                  <v-autocomplete
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('grade')"
                    item-text="value"
                    item-value="value"
                    clearable
                    v-model="item.grade"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.mark`]="{ item }">
                  <v-autocomplete
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('mark')"
                    item-text="value"
                    item-value="value"
                    @change="saveLineItem(item)"
                    clearable
                    v-model="item.mark"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.inventoryCode`]="{ item }">
                  <v-autocomplete
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('inventoryCode')"
                    item-text="value"
                    item-value="value"
                    @change="saveLineItem(item)"
                    clearable
                    v-model="item.inventoryCode"
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.palletType`]="{ item }">
                  <v-autocomplete
                    width="100px"
                    class="ml-1"
                    dense
                    outlined
                    hide-details
                    :items="filteredValues('palletType')"
                    item-text="name"
                    item-value="value"
                    clearable
                    @change="saveLineItem(item)"
                    v-model="item.palletType"
                  ></v-autocomplete>
                </template>

                <template v-slot:footer>
                  <v-list-item
                    @click="addLineItem()"
                    style="background: var(--v-modal-base) !important"
                  >
                    <v-list-item-content class="text-center">
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Select shipment profile -->
    <v-dialog
      v-model="shipmentProfileDialog"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card v-if="shipmentProfileDialog">
        <v-card-title>
          Search Shipment Profiles
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field
                placeholder="Search"
                autofocus
                prepend-inner-icon="search"
                v-model="searchProfiles"
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="profile-info"
            style="cursor: pointer; font-size: 12px"
            :items="filteredProfiles"
            dense
            :loading="loadingShipmentProfiles"
            :headers="shipmentProfileHeaders"
            fixed-header
            :search="searchProfiles"
            @click:row="setShipmentProfile"
            height="70vh"
          >
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Draft'"
                color="blue"
                class="white--text"
                small
                >Draft</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Approved'"
                color="success"
                class="white--text"
                small
                >Approved</v-chip
              >
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allOriginPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allDestinationPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="item.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="
                        item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip
                small
                outlined
                class="mt-1"
                v-for="term in item.consigneeProfileIncoTerms"
                :key="term.id"
                >{{ term.incoTerm }}</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import * as CurrencyCodes from "currency-codes";

export default {
  props: ["seasons", "related", "type", "forecastItem", "updateKey"],
  data: () => ({
    cargoModal: false,
    cargoItem: {},
    cargoHeaders: [
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },

      {
        text: `Type`,
        value: "quantityType",
        align: "center",
      },
      {
        text: `Quantity`,
        value: "quantity",
        align: "center",
      },

      {
        text: "Variety",
        value: "varietyProduct",
        align: "center",
      },
      {
        text: "Class/Grade",
        value: "grade",
        align: "center",
      },
      {
        text: "Brand/Mark",
        value: "mark",
        align: "center",
      },
      {
        text: "Pack",
        value: "packCode",
        align: "center",
      },

      { text: "Count/Size", align: "center", value: "countSize" },
      { text: "PHC", align: "center", value: "phc" },
      { text: "PUC", align: "center", value: "puc" },

      {
        text: "Inventory Code",
        value: "inventoryCode",
        align: "center",
      },
      {
        text: "Pallet Type",
        value: "palletType",
        align: "center",
      },
    ],
    countries: { data: [] },
    currencies: [],
    item: {},
    loadingCountries: false,
    loadingProducts: false,
    loadingShipmentProfiles: false,
    orderSettings: [],
    organisationProductSetting: {},
    products: [],
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      // { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    refreshKey: 100,
    saving: false,
    searchProfiles: null,
    setWeeks: [],
    shippingLines: [],
    shipmentProfileDialog: false,
    shipmentProfiles: {
      data: [],
    },
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    tradedVariations: [],
    weekKey: 250,
  }),
  watch: {
    "item.customerId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getShipmentProfiles(val);
        } else {
          this.shipmentProfiles = { data: [] };
        }
      },
    },
    updateKey(val) {
      this.setWeeks = this.weeks();

      this.refreshKey++;
    },
    "item.seasonId": {
      immediate: true,
      handler(val) {
        this.refreshKey++;
      },
    },
    forecastItem(val) {
      this.item = { ...val };
      this.setWeeks = this.weeks();
      console.log(val);
      console.log(this.setWeeks);
      this.refreshKey++;
    },
    "item": {
      immediate: true,
      handler(val) {
        let test = JSON.parse(JSON.stringify(val));
        console.log(test)
      },
    },
    "item.productId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrganisationProductSetting(val);
        } else {
          this.organisationProductSetting = {};
        }
      },
    },
  },
  computed: {
    constructedContainers() {
      let result = [];
      let containers = [
        ...new Set(this.item.orderCargoItems.map((x) => x.containerIndex)),
      ];
      containers.forEach((x, index) => {
        let container = this.item.orderCargoItems.filter(
          (y) => y.containerIndex == x
        );
        result.push({
          containerIndex: x,
          index: index + 1,
          items: container,
        });
      });
      return result;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) => x.status == "Approved"
      );
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
    filterProducts() {
      let result = [];
      if (this.item.productId) {
        result = this.products.filter(
          (x) =>
            (x.id == this.item.productId ||
              x.parentProductId == this.item.productId) &&
            x.type == "Product"
        );
      } else {
        result = this.products.filter((x) => x.type == "Product");
      }
      return result;
    },
    filterVarieties() {
      let result = [];
      if (this.item.productId) {
        result = this.products.filter(
          (x) => x.parentProductId == this.item.productId && x.type == "Variety"
        );
      }
      return result;
    },
  },
  mounted() {
    this.item = { ...this.forecastItem };
    this.setWeeks = this.weeks();
    this.refreshKey++;
  },
  async created() {
    this.getProducts();
    this.currencies = CurrencyCodes.codes();
    this.getCountries();
    this.getShippingLines();
  },
  methods: {
    addLineItem(containerIndex = 1) {
      this.item.orderCargoItems.push({
        tempId: new Date().getTime(),
        quantity: 0,
        quantityType: 'CT',
        varietyId: null,
        grade: null,
        puc: null,
        countSize: null,
        packCode: null,
        mark: null,
        inventoryCode: null,
        cartons: 0,
        pallets: 0,
        container: 0,
        containerIndex,
      });
    },
    addContainerLineItem() {
      if (this.item.orderCargoItems.length > 0) {
        let max = Math.max(
          ...this.item.orderCargoItems.map((x) => x.containerIndex)
        );
        this.addLineItem(max + 1);
      } else {
        this.addLineItem();
      }
    },
    close() {
      this.$emit("close");
      this.item = {};
    },
    async deleteItem(index, item) {
      if (item.tempId) {
        let find = this.item.orderCargoItems.findIndex(
          (x) => x.tempId == item.tempId
        );
        this.item.orderCargoItems.splice(find, 1);
      } else {
        let find = this.item.orderCargoItems.findIndex((x) => x.id == item.id);
        this.item.orderCargoItems.splice(find, 1);
        await this.$API.updateForecastLineItem({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
    },
    duplicateItem(item) {
      let newItem = { ...item };
      delete newItem.id;
      delete newItem.createdAt;
      delete newItem.updatedAt;
      this.item.orderCargoItems.push({ ...newItem });
    },
    filteredValues(type, item = {}) {
      if (type == "packCode") {
        return this.tradedVariations
          .filter((x) => x.packCode)
          .map((x) => ({
            name: x.packCode,
            value: x.packCode,
          }));
      } else if (type == "countSize") {
        let filtered = this.tradedVariations.filter((x) => x.countSize);
        if (item.packCode) {
          filtered = filtered.filter((x) => x.packCode == item.packCode);
        }
        return filtered.map((x) => ({
          name: x.countSize,
          value: x.countSize,
        }));
      } else if (type == "palletType") {
        return [
          { name: "Standard", value: "S" },
          { name: "Chep", value: "C" },
          { name: "High Cube", value: "H" },
          { name: "Euro", value: "E" },
        ];
      } else {
        return this.orderSettings.filter((item) => item.key === type);
      }
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    async getCountries() {
      this.loadingCountries = true;
      this.countries = await this.$API.searchCountries({});
      this.loadingCountries = false;
    },
    async getOrganisationProductSetting(productId) {
      this.organisationProductSetting =
        await this.$API.getOrganisationTradedProduct(productId);
      this.tradedVariations = await this.$API.getTradedVariationByProduct(
        productId
      );
      this.orderSettings = await this.$API.getOrderSettings(productId);
      if (!this.item.id && this.organisationProductSetting && !this.item.unitPerContainer) {
        this.item.palletised = this.organisationProductSetting.palletised;
        this.item.containerised = this.organisationProductSetting.containerised;
        this.item.palletPerContainer =
          this.organisationProductSetting.palletPerContainer;
        this.item.unitPerPallet = this.organisationProductSetting.unitPerPallet;
        this.item.baseUnit = this.organisationProductSetting.baseUnit;
        this.item.unitPerContainer =
          this.organisationProductSetting.unitPerContainer;
      }
    },
    async getProducts() {
      this.loadingProducts = true;
      let result = await this.$API.getProducts({});
      this.products = result.data;
      this.loadingProducts = false;
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.item.customerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    async getShippingLines() {
      this.shippingLines = await this.$API.getShippingLines({});
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    groupContainers(container){
      console.log(container.items)
      let total = container.items.filter(x=>x.quantity).reduce((a, b) => a + parseInt(b.quantity), 0);
      return this.item.unitPerContainer ? Math.ceil(total/ this.item.unitPerContainer):0
    },
    groupPallets(container){
      // let total = container.items.filter(x=>x.quantity).reduce((a, b) => a + parseInt(b.quantity), 0);
      return this.groupContainers(container) * this.item.palletPerContainer
    },
    validGroup(container){
      let total = container.items.filter(x=>x.quantity).reduce((a, b) => a + parseInt(b.quantity), 0);
      return total % this.item.unitPerContainer === 0
    },
    async saveLineItem(item) {
      if (item.id) {
        await this.$API.updateForecastLineItem(item);
      }
    },
    async save() {
      this.saving = true;
      let result;
      this.item.forecastType = this.type.toUpperCase();
      if (this.item.id) {
        result = await this.$API.updateForecast(this.item);
      } else {
        result = await this.$API.createForecast(this.item);
      }
      this.saving = false;
      this.$emit("save");
      this.close();
    },
    setPlannedYear(e) {
      let find = this.weeks().find((x) => x.name == e);
      this.item.plannedYear = find.plannedYear;
      this.item.plannedWeek = find.plannedWeek;
      this.refreshKey++;
    },
    setSeason(e) {
      let find = this.seasons.find((x) => x.id == e);
      this.item.season = find;
      this.refreshKey++;
    },
    setShipmentProfile(profile) {
      this.item.consigneeProfile = profile;
      this.item.consigneeProfileId = profile.id;
      this.item.destinationCountryId = profile.destinationCountryId;
      this.shipmentProfileDialog = false;
    },
    setVariety(e, data) {
      data.varietyProductId = e.id;
      this.saveLineItem(data);
    },
    weeks() {
      let result = [];
      if (this.item && this.item.season) {
        let start = this.item.season.start
          ? new Date(this.item.season.start)
          : new Date();
        let end = this.item.season.end
          ? new Date(this.item.season.end)
          : new Date();
        let diff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
        let weeks = Math.ceil(diff / 7);
        let currentWeek = moment(start).week();
        let currentYear = moment(start).year();

        for (let i = 0; i < weeks; i++) {
          result.push({
            name: `${currentYear} - W${currentWeek}`,
            plannedWeek: currentWeek,
            plannedYear: currentYear,
          });
          currentWeek++;
          if (currentWeek > 52) {
            currentWeek = 1;
            currentYear++;
          }
        }
      }
      return result;
    },
  },
};
</script>